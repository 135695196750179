import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import services from "../services";

export default withRouter(
	class Header extends React.Component {
		constructor() {
			super();
			this.state = {
				isActive: false,
				peliasConnected: false,
				isDropdownActive: false,
				isLoggedIn: false,
			};
		}

		componentWillMount() {}

		componentWillUpdate() {}

		componentDidMount() {
			services.api.peliasHealthCheck().then((value) => {
				if (value != null) {
					this.setState({ peliasConnected: true });
				}
			});
		}

		componentDidUpdate() {}

		// deprecate this use window location ref instead
		checkLoginScreen = () => {
			if (this.state.isLoggedIn !== true) {
				this.setState({ isLoggedIn: services.auth.isUserLoggedIn() });
			}
		};

		toggleNav = () => {
			this.setState((prevState) => ({
				isActive: !prevState.isActive,
			}));
		};

		toggleDropdown = () => {
			this.setState((prevState) => ({
				isDropdownActive: !prevState.isDropdownActive,
			}));
		};

		onLogoutClick = async () => {
			if (await services.auth.logout()) {
				this.props.history.replace("/login");
				await this.setState({ isLoggedIn: false });
			}
		};

		renderTabs = () => {
			return (
				<div
					className={
						this.state.isActive ? "navbar-menu is-active" : "navbar-menu"
					}
				>
					<div className="navbar-end">
						<div className="navbar-item">
							<NavLink
								onClick={() => {
									if (this.state.peliasConnected === true) {
										alert("CONNECTED");
										return;
									}
									alert("NOT CONNECTED");
									return;
								}}
								to="/"
							>
								<div onClick={() => {}}>
									Pelias{" "}
									<i
										className={`fas ${
											this.state.peliasConnected ? "fa-plug" : "fa-unlink"
										}`}
									/>
								</div>
							</NavLink>
						</div>
						<div className="navbar-item">
							<NavLink onClick={() => {}} to="/tools">
								<div onClick={() => {}}>
									Tools <i className="fas fa-wrench" />
								</div>
							</NavLink>
						</div>
						<div className="navbar-item">
							<NavLink
								onClick={() => {}}
								to="/networkconfig"
								activeClassName="is-active"
							>
								<div onClick={() => {}}>
									Network Config <i className="fas fa-network-wired" />
								</div>
							</NavLink>
						</div>
						<div className="navbar-item">
							<NavLink
								onClick={() => {}}
								to="/doctordata"
								activeClassName="is-active"
							>
								<div onClick={() => {}}>
									View Data <i className="fas fa-list" />
								</div>
							</NavLink>
						</div>
						<div className="navbar-item">
							<NavLink
								onClick={() => {}}
								to="/ingestion"
								activeClassName="is-active"
							>
								<div onClick={() => {}}>
									*New* Ingestion <i className="fas fa-upload" />
								</div>
							</NavLink>
						</div>
						<div className="navbar-item">
							<NavLink onClick={() => {}} to="/" activeClassName="is-active">
								<div onClick={() => this.onLogoutClick()}>
									Logout <i className="fas fa-sign-out-alt" />
								</div>
							</NavLink>
						</div>
					</div>
				</div>
			);
		};

		render() {
			return (
				<div
					style={{
						visibility: services.auth.isUserLoggedIn() ? "visible" : "hidden",
					}}
				>
					<nav
						className={
							services.auth.isUserLoggedIn() ? "navbar" : "navbar is-hidden"
						}
						role="navigation"
						aria-label="main navigation"
					>
						<div className="navbar-brand">
							<a className="navbar-item" href="/">
								{/* <img src={require('images....')} style={{ maxHeight: "none" }} width="200" height="200" /> */}
								<h1 className="title is-primary2-color">Provider Web Tool</h1>
							</a>
							<button
								className={
									this.state.isActive
										? "nav-hamburger button navbar-burger is-active"
										: "nav-hamburger button navbar-burger"
								}
								onClick={this.toggleNav}
								data-target="navbarContent"
							>
								<span style={{ background: "white" }}></span>
								<span style={{ background: "white" }}></span>
								<span style={{ background: "white" }}></span>
							</button>
						</div>
						{this.renderTabs()}
					</nav>
					<hr className="header-hr" />
				</div>
			);
		}
	}
);
